import { cn } from '~/utils/cn';
import { useFormField } from './FormNew';

export interface InputProps extends React.ComponentPropsWithRef<'input'> {}

const Input = ({ ref, className, type, ...props }: InputProps) => {
  const formField = useFormField();

  return (
    <input
      type={type}
      className={cn(
        'flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-2 ring-transparent ring-offset-background transition-all ease-in file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:italic placeholder:text-muted-foreground/50 focus-visible:outline-hidden focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
        className,
      )}
      autoComplete="off"
      ref={ref}
      {...props}
      {...formField.props}
    />
  );
};
Input.displayName = 'Input';

export { Input };
